import { useScriptClarity, useScriptGoogleAnalytics, useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const googleAnalytics = useScriptGoogleAnalytics({"id":"G-GFCWF7MF31"})
    const clarity = useScriptClarity({"id":"owaudar9ka"})
    return { provide: { $scripts: { googleAnalytics, clarity } } }
  }
})