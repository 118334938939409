import { default as _91slug_93yuCKbhA6rwMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/[slug].vue?macro=true";
import { default as about_45uspAOBuQBNQGMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/about-us.vue?macro=true";
import { default as about5YYaEpjkGBMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/about.vue?macro=true";
import { default as indexcpQUQG5afeMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46page_93vYmy4u3AoJMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/blog/page/[...page].vue?macro=true";
import { default as _91slug_93TeaXPpB4MpMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/blog2/[slug].vue?macro=true";
import { default as careerspSjaab2qzjMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/careers.vue?macro=true";
import { default as indexkIjZgaOkZcMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/category/[category]/index.vue?macro=true";
import { default as _91_46_46_46page_932VoqyJqu3NMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/category/[category]/page/[...page].vue?macro=true";
import { default as _91_46_46_46params_93CRtHV7Hx14Meta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/connect/sfdc-expert/[...params].vue?macro=true";
import { default as _91_46_46_46params_938x69QvMgO3Meta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/connect/sfdc-partner/[...params].vue?macro=true";
import { default as contactskdks2YrMXMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/contact.vue?macro=true";
import { default as e_45signature_45successJr2deZFpUXMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/demo/e-signature-success.vue?macro=true";
import { default as e_45signaturefD6E7G87yJMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/demo/e-signature.vue?macro=true";
import { default as indexKU6PAwskAYMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/demo/index.vue?macro=true";
import { default as survey_45successsYw30P8jzZMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/demo/survey-success.vue?macro=true";
import { default as surveyzTEsIawnSwMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/demo/survey.vue?macro=true";
import { default as indexy7GI9Q411QMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/index.vue?macro=true";
import { default as salesforce_45wordpress_45integrationEopoATTs7RMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/services/salesforce-wordpress-integration.vue?macro=true";
import { default as indexUDMOOUVi8oMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/tools/index.vue?macro=true";
import { default as pdf_45generation_45guide2VcliZuc9yMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/tools/pdf-generation-guide.vue?macro=true";
import { default as salesforce_45pdf_45builderWLWi5Kd6LYMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/tools/salesforce-pdf-builder.vue?macro=true";
import { default as salesforce_45web_45to_45case_45form_45builderDnZucgYpWbMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/tools/salesforce-web-to-case-form-builder.vue?macro=true";
import { default as salesforce_45web_45to_45lead_45form_45builderHqYnERDXXwMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/tools/salesforce-web-to-lead-form-builder.vue?macro=true";
import { default as web_45to_45case_45guidegZZfBwwBtIMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/tools/web-to-case-guide.vue?macro=true";
import { default as web_45to_45lead_45guideY59YpgKNqBMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/tools/web-to-lead-guide.vue?macro=true";
import { default as component_45stubEpmuqfdlxmMeta } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubEpmuqfdlxm } from "C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug()",
    meta: _91slug_93yuCKbhA6rwMeta || {},
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/[slug].vue")
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/about-us.vue")
  },
  {
    name: "about",
    path: "/about",
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/about.vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/blog/index.vue")
  },
  {
    name: "blog-page-page",
    path: "/blog/page/:page(.*)*",
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/blog/page/[...page].vue")
  },
  {
    name: "blog2-slug",
    path: "/blog2/:slug()",
    meta: _91slug_93TeaXPpB4MpMeta || {},
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/blog2/[slug].vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/careers.vue")
  },
  {
    name: "category-category",
    path: "/category/:category()",
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/category/[category]/index.vue")
  },
  {
    name: "category-category-page-page",
    path: "/category/:category()/page/:page(.*)*",
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/category/[category]/page/[...page].vue")
  },
  {
    name: "connect-sfdc-expert-params",
    path: "/connect/sfdc-expert/:params(.*)*",
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/connect/sfdc-expert/[...params].vue")
  },
  {
    name: "connect-sfdc-partner-params",
    path: "/connect/sfdc-partner/:params(.*)*",
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/connect/sfdc-partner/[...params].vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/contact.vue")
  },
  {
    name: "demo-e-signature-success",
    path: "/demo/e-signature-success",
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/demo/e-signature-success.vue")
  },
  {
    name: "demo-e-signature",
    path: "/demo/e-signature",
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/demo/e-signature.vue")
  },
  {
    name: "demo",
    path: "/demo",
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/demo/index.vue")
  },
  {
    name: "demo-survey-success",
    path: "/demo/survey-success",
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/demo/survey-success.vue")
  },
  {
    name: "demo-survey",
    path: "/demo/survey",
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/demo/survey.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/index.vue")
  },
  {
    name: "services-salesforce-wordpress-integration",
    path: "/services/salesforce-wordpress-integration",
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/services/salesforce-wordpress-integration.vue")
  },
  {
    name: "tools",
    path: "/tools",
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/tools/index.vue")
  },
  {
    name: "tools-pdf-generation-guide",
    path: "/tools/pdf-generation-guide",
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/tools/pdf-generation-guide.vue")
  },
  {
    name: "tools-salesforce-pdf-builder",
    path: "/tools/salesforce-pdf-builder",
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/tools/salesforce-pdf-builder.vue")
  },
  {
    name: "tools-salesforce-web-to-case-form-builder",
    path: "/tools/salesforce-web-to-case-form-builder",
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/tools/salesforce-web-to-case-form-builder.vue")
  },
  {
    name: "tools-salesforce-web-to-lead-form-builder",
    path: "/tools/salesforce-web-to-lead-form-builder",
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/tools/salesforce-web-to-lead-form-builder.vue")
  },
  {
    name: "tools-web-to-case-guide",
    path: "/tools/web-to-case-guide",
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/tools/web-to-case-guide.vue")
  },
  {
    name: "tools-web-to-lead-guide",
    path: "/tools/web-to-lead-guide",
    component: () => import("C:/Users/Kritik/Documents/GitHub/arrify-nuxt-1/pages/tools/web-to-lead-guide.vue")
  },
  {
    name: component_45stubEpmuqfdlxmMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubEpmuqfdlxm
  },
  {
    name: component_45stubEpmuqfdlxmMeta?.name,
    path: "/demo-sitemap.xml",
    component: component_45stubEpmuqfdlxm
  },
  {
    name: component_45stubEpmuqfdlxmMeta?.name,
    path: "/tools-sitemap.xml",
    component: component_45stubEpmuqfdlxm
  },
  {
    name: component_45stubEpmuqfdlxmMeta?.name,
    path: "/pages-sitemap.xml",
    component: component_45stubEpmuqfdlxm
  },
  {
    name: component_45stubEpmuqfdlxmMeta?.name,
    path: "/blog-sitemap.xml",
    component: component_45stubEpmuqfdlxm
  },
  {
    name: component_45stubEpmuqfdlxmMeta?.name,
    path: "/categories-sitemap.xml",
    component: component_45stubEpmuqfdlxm
  },
  {
    name: component_45stubEpmuqfdlxmMeta?.name,
    path: "/connect-sitemap.xml",
    component: component_45stubEpmuqfdlxm
  }
]